import React, { useEffect, useState } from 'react'
import { Flex, Box, Button, Label, Radio, Checkbox } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import ContentText from '@solid-ui-components/ContentText'
import TeklifInput from '@solid-ui-components/ContentForm/TeklifInput'
import toast, { Toaster } from 'react-hot-toast'
import Tabs from '@solid-ui-components/Tabs'


const styles = {

  card: {
    overflow: `hidden`,
    margin: "50px"
  },

}



const PrmTeklifFrmAyarEdit = ({ ...props }) => {


  const [dt, setDt] = useState(null);
  const [firtProcess, setfirtProcess] = useState("1");

  const [enBoy, setenBoy] = useState(false);
  const [baski, setbaski] = useState(false);
  const [urunTipi, seturunTipi] = useState(false);
  const [levhaTipi, setlevhaTipi] = useState(false);
  const [levhaKalite, setlevhaKalite] = useState(false);

  const [renk, setrenk] = useState(false);
  const [yapistirma, setyapistirma] = useState(false);
  const [ekOzellik, setekOzellik] = useState(false);
  const [selefon, setselefon] = useState(false);
  const [kartoncinsi, setkartoncinsi] = useState(false);


  useEffect(() => {
    prmDataGetir();
  }, []);

  function prmDataGetir() {

    httpServiceToken.post("Locked/PrmDataGetir", {}).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data);
      } else {
        setDt(null);
      }

    }).catch((err) => {
      setDt(null);

    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && firtProcess == "1") {

      if (dt) {
        setenBoy(dt.Prv.TeklifIcerik_EnBoyYukseklikGosterilsinMi ?? false);
        setbaski(dt.Prv.TeklifIcerik_BaskiGosterilsinMi ?? false);
        seturunTipi(dt.Prv.TeklifIcerik_UrunTipiGosterilsinMi ?? false);
        setlevhaTipi(dt.Prv.TeklifIcerik_LevhaDalgasiGosterilsinMi ?? false);
        setlevhaKalite(dt.Prv.TeklifIcerik_LevhaKalitesiGosterilsinMi ?? false);
        setrenk(dt.Prv.TeklifIcerik_RenkGosterilsinMi ?? false);
        setyapistirma(dt.Prv.TeklifIcerik_YapistirmaGosterilsinMi ?? false);
        setekOzellik(dt.Prv.TeklifIcerik_EkOzellikGosterilsinMi ?? false);
        setselefon(dt.Prv.TeklifIcerik_SelefonGosterilsinMi ?? false);
        setkartoncinsi(dt.Prv.TeklifIcerik_KartonCinsiGosterilsinMi ?? false);

        setfirtProcess("2");

      }
    }
  }, [dt]);

  return <Box id={`pnl-${0}`} >
    <ContentContainer
      content={{
        variant: "cards.interactive",
      }}
      variant='cards.interactive'
      sx={styles.card} >
      <Toaster />

      <Flex
        sx={{
          alignItems: `center`,
          flexDirection: [
            `column`,
            `row`
          ]
        }}
      >
        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
          <Box sx={{ textAlign: `center` }}>
            <ContentText content={{
              text: "Teklif Formundaki Bilgileri Gizle/Göster",
              color: "omegaDarker",
              variant: "h5",
              space: 2
            }} />

          </Box>
          <Box>
            <Divider space={1} />

            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`,

                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small"
                  }}>
                    En Boy Yükseklik ve İç Dış Ölçü Bilgisi Gösterilsin Mi?
                  </Box>

                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-enboy" checked={enBoy == true} onChange={() => {
                          setenBoy(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-enboy" checked={enBoy == false} onChange={() => {
                          setenBoy(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small",

                  }}>
                    Baskı Durumu Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-baski" checked={baski == true} onChange={() => {
                          setbaski(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-baski" checked={baski == false} onChange={() => {
                          setbaski(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`,

                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small"
                  }}>
                    Ürün tipi Oluklu veya Karton Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-urunTipi" checked={urunTipi == true} onChange={() => {
                          seturunTipi(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-urunTipi" checked={urunTipi == false} onChange={() => {
                          seturunTipi(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small",

                  }}>
                    Kullanılan Oluklu Şekli  Ör: E-Dalga  Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-levhaTipi" checked={levhaTipi == true} onChange={() => {
                          setlevhaTipi(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-levhaTipi" checked={levhaTipi == false} onChange={() => {
                          setlevhaTipi(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`,

                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small"
                  }}>
                    Kendi Kalite Adınız Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-levhaKalite" checked={levhaKalite == true} onChange={() => {
                          setlevhaKalite(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-levhaKalite" checked={levhaKalite == false} onChange={() => {
                          setlevhaKalite(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex> 
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small",

                  }}>
                    Renk Sayısı Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-renk" checked={renk == true} onChange={() => {
                          setrenk(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-renk" checked={renk == false} onChange={() => {
                          setrenk(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`,

                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small"
                  }}>
                    Yapistirma Sekli Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-yapistirma" checked={yapistirma == true} onChange={() => {
                          setyapistirma(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-yapistirma" checked={yapistirma == false} onChange={() => {
                          setyapistirma(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small",

                  }}>
                    Ek Özellik Lak- Yaldız- Gofre Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-ekOzellik" checked={ekOzellik == true} onChange={() => {
                          setekOzellik(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-ekOzellik" checked={ekOzellik == false} onChange={() => {
                          setekOzellik(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`,

                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small"
                  }}>
                    Selefon Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-selefon" checked={selefon == true} onChange={() => {
                          setselefon(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-selefon" checked={selefon == false} onChange={() => {
                          setselefon(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],

                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <Box
                  sx={{
                    border: "1px #D0D4CA solid",
                    borderRadius: "10px",
                    p: '0.9rem',
                  }}
                >
                  <Box sx={{
                    fontSize: "small",

                  }}>
                    Karton kutu cinsi örnek Bristol Gösterilsin Mi?
                  </Box>
                  <Flex>
                    <Box sx={{ px: [1] }} >
                      <Label>
                        <input type="radio" name="radio-group-kartoncinsi" checked={kartoncinsi == true} onChange={() => {
                          setkartoncinsi(true);
                        }} />
                        Göster
                      </Label>
                    </Box>
                    <Box sx={{ px: [5] }}>
                      <Label>
                        <input type="radio" name="radio-group-kartoncinsi" checked={kartoncinsi == false} onChange={() => {
                          setkartoncinsi(false);
                        }} />
                        Gizle
                      </Label>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>

              <div className="profil-btn">
                <Button type="submit"
                  index={3}
                  aria-label="parametre bilgisi kaydet"
                  onClick={e => {
                    let prmetre = {
                      Prv: {
                        TeklifIcerik_EnBoyYukseklikGosterilsinMi: enBoy,
                        TeklifIcerik_BaskiGosterilsinMi: baski,
                        TeklifIcerik_UrunTipiGosterilsinMi: urunTipi,
                        TeklifIcerik_LevhaDalgasiGosterilsinMi: levhaTipi,
                        TeklifIcerik_LevhaKalitesiGosterilsinMi: levhaKalite,
                        TeklifIcerik_RenkGosterilsinMi: renk,
                        TeklifIcerik_YapistirmaGosterilsinMi: yapistirma,
                        TeklifIcerik_EkOzellikGosterilsinMi: ekOzellik,
                        TeklifIcerik_SelefonGosterilsinMi: selefon,
                        TeklifIcerik_KartonCinsiGosterilsinMi: kartoncinsi,
                      },
                      Inf: null
                    }
                    httpServiceToken.post("Locked/TeklifteGosterPrmSave", { params: prmetre }).then((res) => {
                      if (res.DogruMu) {
                        toast.success(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                        prmDataGetir();

                      } else {
                        toast.error(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      }
                    }).catch((err) => {

                    });
                  }
                  }
                >
                  Kaydet
                </Button>
              </div>
            </Box>

            <Divider space={1} />
          </Box>
        </Box>
      </Flex>
    </ContentContainer>
  </Box>

}

export default WithDefaultContent(PrmTeklifFrmAyarEdit)


