import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import KtLog from '../../../../../packages/solid-ui/solid-ui-blocks/src/KtLog/KtLog'
import ParametreEdit from '@solid-ui-blocks/Parametreler'


const ParametrePage = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
 

  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Parametre ve Ayarlar' />
      <Divider space='5' /> 
      <KtLog> 
      <ParametreEdit/>
      </KtLog>
      <Divider space='5' /> 
      <Divider space='5' /> 
      <Divider space='5' /> 
      <Divider space='5' /> 
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageParametreBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default ParametrePage
