import React, { useEffect, useState } from 'react'
import { Flex, Box, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import ContentText from '@solid-ui-components/ContentText'
import TeklifInput from '@solid-ui-components/ContentForm/TeklifInput'
import toast, { Toaster } from 'react-hot-toast'


const styles = {
  
  card: {
    overflow: `hidden`,
    margin: "50px"
  },
  
}



const PrmSiparisGenelEdit = ({ ...props }) => {


  const [dt, setDt] = useState(null);
  const [firtProcess, setfirtProcess] = useState("1");

  const [onEk, setonEk] = useState("");
  const [sipBaslangic, setsipBaslangic] = useState(0);



  useEffect(() => {
    prmDataGetir();
  }, []);

  function prmDataGetir() {

    httpServiceToken.post("Locked/PrmDataGetir", {}).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data);
      } else {
        setDt(null);
      }

    }).catch((err) => {
      setDt(null);

    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && firtProcess == "1") {

      if (dt) {
        setonEk(dt.Prv.SiparisOnEki ?? "");
        setsipBaslangic(dt.Prv.SipBaslangicNo ?? 0);
      
        setfirtProcess("2");

      }
    }
  }, [dt]);

  return <Box id={`pnl-${0}`} >
    <ContentContainer
      content={{
        variant: "cards.interactive",
      }}
      variant='cards.interactive'
      sx={styles.card} >
      <Toaster />

      <Flex
        sx={{
          alignItems: `center`,
          flexDirection: [
            `column`,
            `row`
          ]
        }}
      >
        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
          <Box sx={{ textAlign: `center` }}>
            <ContentText content={{
              text: "Sipariş Doldururken Kullanılacak Varsayılan Ayarlar",
              color: "omegaDarker",
              variant: "h5",
              space: 2
            }} />

          </Box>
          <Box>
            <Divider space={1} />

            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={onEk}
                  onChange={(value) => {
                    setonEk(value.target.value);
                  }}
                  placeholder="Sipariş Ön Eki"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Örnek SİP + 2310 + 00001
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  ÖN EK + YılAy+5Hane
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={sipBaslangic}
                  onChange={(value) => {
                    setsipBaslangic(value.target.value);
                  }}
                  placeholder="Sipariş Başlangıç Numarası"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Sipariş başlangıç numarası
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Girdiğini numara bundan sonraki ilk siparişe verilecektir
                </Box>
               
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
               
              </Box>
            </Flex>
   
            <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>

              <div className="profil-btn">
                <Button type="submit"
                  index={3}
                  aria-label="parametre bilgisi kaydet"
                  onClick={e => {
                    let prmetre = {
                      Prv: {
                        SiparisOnEki: onEk,
                        SipBaslangicNo: sipBaslangic,
                      },
                      Inf: null
                    }
                    httpServiceToken.post("Locked/SiparisGenelPrmSave", { params: prmetre }).then((res) => {
                      if (res.DogruMu) {
                        toast.success(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      } else {
                        toast.error(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      }
                    }).catch((err) => {

                    });
                  }
                  }
                >
                  Kaydet
                </Button>
              </div>
            </Box>

            <Divider space={3} />
          </Box>
        </Box>
      </Flex>
    </ContentContainer>
  </Box>

}

export default WithDefaultContent(PrmSiparisGenelEdit)


