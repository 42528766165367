import React, { useEffect, useState } from 'react'
import { Flex, Box, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import ContentText from '@solid-ui-components/ContentText'
import TeklifInput from '@solid-ui-components/ContentForm/TeklifInput'
import toast, { Toaster } from 'react-hot-toast'


const styles = {
  
  card: {
    overflow: `hidden`,
    margin: "50px"
  },
  
}



const PrmTeklifGenelEdit = ({ ...props }) => {


  const [dt, setDt] = useState(null);
  const [firtProcess, setfirtProcess] = useState("1");

  const [onEk, setonEk] = useState("");
  const [kdv, setKdv] = useState(0);
  const [gecerlilik, setgecerlilik] = useState(0);
  const [uretim, seturetim] = useState(0);
  const [grafik, setgrafik] = useState(0);
  const [isk, setisk] = useState(0);
  const [maxisk, setmaxisk] = useState(0);



  useEffect(() => {
    prmDataGetir();
  }, []);

  function prmDataGetir() {

    httpServiceToken.post("Locked/PrmDataGetir", {}).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data);
      } else {
        setDt(null);
      }

    }).catch((err) => {
      setDt(null);

    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && firtProcess == "1") {

      if (dt) {
        setonEk(dt.Prv.TeklifOnEki ?? "");
        setKdv(dt.Inf.KDVOrani ?? 0);
        setgecerlilik(dt.Inf.TeklifGecerlilikSuresi ?? 0);
        seturetim(dt.Prv.VarsayilanUretimSuresi ?? 0);
        setgrafik(dt.Prv.GrafikMaliyeti ?? 0);
        setisk(dt.Inf.VarsayilanIskontoOrani ?? 0);
        setmaxisk(dt.Inf.MaxIskontoOrani ?? 0);

        setfirtProcess("2");

      }
    }
  }, [dt]);

  return <Box id={`pnl-${0}`} >
    <ContentContainer
      content={{
        variant: "cards.interactive",
      }}
      variant='cards.interactive'
      sx={styles.card} >
      <Toaster />

      <Flex
        sx={{
          alignItems: `center`,
          flexDirection: [
            `column`,
            `row`
          ]
        }}
      >
        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
          <Box sx={{ textAlign: `center` }}>
            <ContentText content={{
              text: "Teklif Doldururken Kullanılacak Varsayılan Ayarlar",
              color: "omegaDarker",
              variant: "h5",
              space: 2
            }} />

          </Box>
          <Box>
            <Divider space={1} />

            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={onEk}
                  onChange={(value) => {
                    setonEk(value.target.value);
                  }}
                  placeholder="Teklif Ön Eki"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Örnek TEK + 2310 + 00001
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  ÖN EK + YılAy+5Hane
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={kdv}
                  onChange={(value) => {
                    setKdv(value.target.value);
                  }}
                  placeholder="Satış KDV Oranı"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Teklif Verilirken
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Uygulanacak KDV Oranı
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={gecerlilik}
                  onChange={(value) => {
                    setgecerlilik(value.target.value);
                  }}
                  placeholder="Teklif Geçerlilik Süresi(Gün)"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Teklif Bu Süreyi Aştıktan Sonra
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Otomatik olarak arşivlenen tekfliflere düşer
                </Box>
              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={uretim}
                  onChange={(value) => {
                    seturetim(value.target.value);
                  }}
                  placeholder="Varsayılan Üretim Süresi(Gün)"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Teklifin taahhüt edilen üretim süresi
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Üretim süresini teklif bazlı değiştirebilirsiniz
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={grafik}
                  onChange={(value) => {
                    setgrafik(value.target.value);
                  }}
                  placeholder="Varsayılan Grafiker Maliyeti"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Varsayılan grafiker maliyeti teklif bazlı değiştirilebilir
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Sıfır yazarsanız grafiker maliyet sorusu sorulmayacaktır
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >


              </Box>
            </Flex>
            <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >
              {/*   <Box
                              sx={{
                                display: [`none`, null, `block`],
                                flex: 1,
                                p: '0.9rem',
                                borderRadius: `left`,
                                overflow: `hidden`
                              }}
                            >
                             <Box sx={{
                                fontSize: "small"
                              }}>
                                İskonto Uygulaması Var Mı?
                              </Box>
                              <Tabs sx={{ flex: 1, p: 20, paddingBottom: 20 }} tabs={["Evet Var", "Hayır Yok"]} onChange=
                                {e => {


                                }}
                                variant='pill'>
                                <div></div>
                                <div></div>
                              </Tabs>
                          <Divider space={1} />

                              <Box sx={{
                                fontSize: "x-small"
                              }}>
                             Hayır seçerseniz 
                              </Box>
                              <Box sx={{
                                fontSize: "x-small"
                              }}>
                               Teklif bazlı Sıfır geçerseniz Formlarda iskonto satırı gösterilmez
                              </Box>
                            </Box> */}
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={isk}
                  onChange={(value) => {
                    setisk(value.target.value);
                  }}
                  placeholder="Varsayılan İskonto Oranı"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Sıfır geçebilirsiniz
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Teklif doldururken sıfır geçerseniz İskonto satırı formlarda gösterilmez
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >
                <TeklifInput
                  {...props}
                  value={maxisk}
                  onChange={(value) => {
                    setmaxisk(value.target.value);
                  }}
                  placeholder="Maximum İskonto Oranı"

                />
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Sıfır geçebilirsiniz
                </Box>
                <Box sx={{
                  fontSize: "x-small"
                }}>
                  Tüm tekliflerde girilen değerin üstünde iskonto yapılamaz
                </Box>
              </Box>
              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >


              </Box>
            </Flex>
            <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>

              <div className="profil-btn">
                <Button type="submit"
                  index={3}
                  aria-label="parametre bilgisi kaydet"
                  onClick={e => {
                    let prmetre = {
                      Prv: {
                        TeklifOnEki: onEk,
                        GrafikMaliyeti: grafik,
                        VarsayilanUretimSuresi: uretim
                      },
                      Inf: {
                        KDVOrani: kdv,
                        TeklifGecerlilikSuresi: gecerlilik,
                        VarsayilanIskontoOrani: isk,
                        MaxIskontoOrani: maxisk
                      }
                    }
                    httpServiceToken.post("Locked/TeklifGenelPrmSave", { params: prmetre }).then((res) => {
                      if (res.DogruMu) {
                        toast.success(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      } else {
                        toast.error(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      }
                    }).catch((err) => {

                    });
                  }
                  }
                >
                  Kaydet
                </Button>
              </div>
            </Box>

            <Divider space={3} />
          </Box>
        </Box>
      </Flex>
    </ContentContainer>
  </Box>

}

export default WithDefaultContent(PrmTeklifGenelEdit)


