import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, IconButton, Text, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
// import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import httpServiceToken from '../../../../../site/src/service/httpServiceToken'
import { setKtTokenSifre, getKtLogin, logOut } from '../../../../../site/src/service/auth'
import loadsGif from '../../assets/loads.gif'
import Tabs from '@solid-ui-components/Tabs'
// import PrmTeklifGenelEdit from './PrmTeklifGnl'
import PrmTeklifGenelEdit from './PrmTeklifGenel'
import PrmTeklifKosulEdit from './PrmTeklifKosul'
import PrmSiparisKosulEdit from './PrmSiparisKosul'
import PrmTeklifFrmAyarEdit from './TeklifFrmAyar'
import PrmSiparisGenelEdit from './PrmSiparisGenel'
import PrmSatinAlmaEdit from './PrmSatinAlma'
import PrmUretimEdit from './PrmUretim'







const ParametreEdit = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)


  const [btnID, setbtnID] = useState("");
  const formId = "frmParametre"




  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {

      } else {
        logOut();
        setDt(null);
        setLdg(true);
      }
    }
  }, []);

  return (
    <>
    
    <Container>

      <Box>
        <div className="dash-tab">
          <Tabs sx={{ flex: 1, p: 20, paddingBottom: 20 }} tabs={["Teklif Ayarları", "Teklif Koşulları", "Teklif Formu Ayarları", "Sipariş Ayarları", "Sipariş Koşulları", "Satın Alma", "Üretim"]} 
            variant='pill'>
            <PrmTeklifGenelEdit></PrmTeklifGenelEdit>
            <PrmTeklifKosulEdit></PrmTeklifKosulEdit>
            <PrmTeklifFrmAyarEdit></PrmTeklifFrmAyarEdit>
            <PrmSiparisGenelEdit></PrmSiparisGenelEdit>
            <PrmSiparisKosulEdit></PrmSiparisKosulEdit>
            <PrmSatinAlmaEdit></PrmSatinAlmaEdit>
            <PrmUretimEdit></PrmUretimEdit>
          </Tabs>
        </div>

      </Box>


    

    </Container >
    <Divider space={450} /></>
  )
}

export default WithDefaultContent(ParametreEdit)


