import React, { useEffect, useState } from 'react'
import { Flex, Box, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import ContentText from '@solid-ui-components/ContentText'
import TeklifInput from '@solid-ui-components/ContentForm/TeklifInput'
import toast, { Toaster } from 'react-hot-toast'


const styles = {

  card: {
    overflow: `hidden`,
    margin: "50px"
  },

}



const PrmTeklifKosulEdit = ({ ...props }) => {

  const [dt, setDt] = useState(null);
  const [firtProcess, setfirtProcess] = useState("1");

  const [k1, setk1] = useState("");
  const [k2, setk2] = useState("");
  const [k3, setk3] = useState("");
  const [k4, setk4] = useState("");
  const [k5, setk5] = useState("");
  const [k6, setk6] = useState("");
  const [k7, setk7] = useState("");
  const [k8, setk8] = useState("");
  const [k9, setk9] = useState("");
  const [k10, setk10] = useState("");







  useEffect(() => {
    prmDataGetir();
  }, []);

  function prmDataGetir() {

    httpServiceToken.post("Locked/PrmDataGetir", {}).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data);
      } else {
        setDt(null);
      }

    }).catch((err) => {
      setDt(null);

    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && firtProcess == "1") {

      if (dt) {

        setk1(dt.Inf.TeklifKosullari1 ?? "");
        setk2(dt.Inf.TeklifKosullari2 ?? "");
        setk3(dt.Inf.TeklifKosullari3 ?? "");
        setk4(dt.Inf.TeklifKosullari4 ?? "");
        setk5(dt.Inf.TeklifKosullari5 ?? "");
        setk6(dt.Inf.TeklifKosullari6 ?? "");
        setk7(dt.Inf.TeklifKosullari7 ?? "");
        setk8(dt.Inf.TeklifKosullari8 ?? "");
        setk9(dt.Inf.TeklifKosullari9 ?? "");
        setk10(dt.Inf.TeklifKosullari10 ?? "");
        setfirtProcess("2");

      }
    }
  }, [dt]);

  return <Box id={`pnl-${0}`} >
    <ContentContainer
      content={{
        variant: "cards.interactive",
      }}
      variant='cards.interactive'
      sx={styles.card} >

      <Flex
        sx={{
          alignItems: `center`,
          flexDirection: [
            `column`,
            `row`
          ]
        }}
      >
        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
          <Box sx={{ textAlign: `center` }}>
            <ContentText content={{
              text: "Teklif Koşulları",
              color: "omegaDarker",
              variant: "h5",
              space: 2
            }} />

          </Box>
          <Box>
            <Box sx={{
              fontSize: "x-small"
            }}>
              Teklif formunda yazılacak olan koşullar
            </Box>
            <Box sx={{
              fontSize: "x-small"
            }}>
              Boş geçilebilir
            </Box>
            <Divider space={1} />
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k1}
                onChange={(value) => {
                  setk1(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k2}
                onChange={(value) => {
                  setk2(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k3}
                onChange={(value) => {
                  setk3(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k4}
                onChange={(value) => {
                  setk4(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k5}
                onChange={(value) => {
                  setk5(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k6}
                onChange={(value) => {
                  setk6(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k7}
                onChange={(value) => {
                  setk7(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k8}
                onChange={(value) => {
                  setk8(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k9}
                onChange={(value) => {
                  setk9(value.target.value);
                }}

              />

            </Box>
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                pl: '0.9rem',
                p: '5px',
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <TeklifInput
                {...props}
                value={k10}
                onChange={(value) => {
                  setk10(value.target.value);
                }}

              />

            </Box>
            <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>

              <div className="profil-btn">
                <Button type="submit"
                  index={3}
                  aria-label="parametre bilgisi kaydet"
                  onClick={e => {
                    let prmetre = {
                      Prv: null,
                      Inf: {
                        TeklifKosullari1: k1,
                        TeklifKosullari2: k2,
                        TeklifKosullari3: k3,
                        TeklifKosullari4: k4,
                        TeklifKosullari5: k5,
                        TeklifKosullari6: k6,
                        TeklifKosullari7: k7,
                        TeklifKosullari8: k8,
                        TeklifKosullari9: k9,
                        TeklifKosullari10: k10
                      }
                    }
                    httpServiceToken.post("Locked/TeklifKosulPrmSave", { params: prmetre }).then((res) => {
                      if (res.DogruMu) {
                        toast.success(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      } else {
                        toast.error(res.Mesaj, {
                          duration: 5000,
                          position: 'top-left'
                        });
                      }
                    }).catch((err) => {

                    });
                  }
                  }
                >
                  Kaydet
                </Button>
                <Toaster />
              </div>
            </Box>

            <Divider space={3} />
          </Box>
        </Box>
      </Flex>
    </ContentContainer>
  </Box >

}

export default WithDefaultContent(PrmTeklifKosulEdit)


